import * as React from "react"

import Layout from "../components/layout"
import SEOComponent from "../components/seo"
import { Hero } from "../components/hero"

const NotFoundPage = () => (
  <Layout>
    <SEOComponent title="404: Not found" />
    <Hero header="404: Not found" />
    <section className="container mx-auto my-4 space-y-4">
      <h2 className="text-3xl">Oops! The page you are looking for doesn't exist.</h2>
      <p className="text-lg sm:text-xl">Please return to the home page or navigation to find the page you are looking for.</p>
    </section>
  </Layout>
)

export default NotFoundPage
